import React from 'react'
import {graphql, StaticQuery} from "gatsby";
import {colours} from "../styles/colours";
import InstagramIcon from "@material-ui/icons/Instagram";

const pageStyles = {
    width: "100%",
    height: "100%",
    margin: 0,
    padding: 0
}

const backgroundStyle = {
    backgroundColor: colours.yellow,
    paddingTop: "20px",
    width: "100vw",
    height: "100vh",
    display: "flex"
}

const mainContentStyle = {
    maxWidth: "500px",
    height: "100vh",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    position: "relative"
}

const thankYouHeaderStyle = {
    marginTop: "60%",
    textAlign: "center",
    width: "100%",
    margin: 0,
    fontSize: "3em",
    color: colours.red,
}

const thankYouMessageStyle = {
    textAlign: "center",
    width: "100%",
    margin: 0
}

const instagramContainer = {
    borderTop: `2px solid ${colours.red}`,
    width: "100%",
    marginTop: "30px",
    paddingTop: "30px"
}

const instagramPlug = {
    width: "100%",
    float: "left",
    textDecoration: "none",
    textAlign: "center",
    textShadow: "none",
    backgroundImage: "none",
    color: colours.grey,
    margin: 0,
    padding: 0
}

const instagramIcon = {
    width: "4em",
    height: "4em",
    color: colours.red,
    margin: 0,
    padding: 0
}

const ThankYouPage = ({data}) => {
  return (
      <StaticQuery query={graphql`
        {
          allContentfulThankYouPage {
            edges {
              node {
                thankYouMessage {
                  thankYouMessage
                }
              }
            }
          }
        }
        `
      }
       render={data => (
           <main style={pageStyles}>
               <title>Thank You!</title>
               <div style={backgroundStyle}>
                   <div style={mainContentStyle}>
                       <h1 style={thankYouHeaderStyle}>Thank You!</h1>
                       <h3 style={thankYouMessageStyle}>{data.allContentfulThankYouPage.edges[0].node.thankYouMessage.thankYouMessage}</h3>
                       <div style={instagramContainer}>
                           <a style={instagramPlug} href={"https://www.instagram.com/suze_bakes/"}>
                               <p style={{padding: 0, margin: 0}}>Follow me on Instagram for updates</p>
                               <InstagramIcon style={instagramIcon} className={"instagramIcon"}/>
                           </a>
                       </div>
                   </div>
               </div>
           </main>)}
      />
  )
}

export default ThankYouPage
